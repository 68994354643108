import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { APP_VERSION } from '@ttc/ttc-constants/app.metadata';
import * as bowser from 'bowser';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  take,
  tap,
} from 'rxjs/operators';

import { AuthService, NO_TOKEN } from './auth.service';
import {
  LocalStorageService,
  TENANT_TOKEN_KEY,
} from '@main-client/src/app/core/local-storage.service';
import { TenantService } from '@main-client/src/app/tenant/tenant.service';

import { Tenant } from '@libs/src/tenant/tenant.interface';
import { generateSubdomainUrl } from '@libs/src/utilities/url.utilities';
import { IAppState } from '@main-client/src/app/app.state';

import { APP_NAME } from '../app.metadata';

import inRange from 'lodash-es/inRange';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import startsWith from 'lodash-es/startsWith';

const APP_NAME_TOKEN_KEY = 'TTC-App-Name';
const APP_TOKEN_KEY = 'TTC-App-Version';
const API_PREFIX = '/api/';
const API_TENANT_URL = '/api/v2/tenant';
const HTTP_STATUS_BAD_REQUEST = 400;
const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_NOT_FOUND = 404;
const HTTP_GET_METHOD = 'GET';
const INVALID_TENANT_TOKEN_ERROR_NAME = 'tenant/invalid-token';
const REFRESH_TOKEN_API_URL = '/api/v2/auth/token';
const TENANT_HEADER_TOKEN_KEY = 'ttc-tenant-token';
const isIE =
  bowser.msie && inRange(parseInt(bowser.version as string, 10), 9, 12);

class CustomHttpUrlEncoder extends HttpUrlEncodingCodec {
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  customHttpUrlEncoder = new CustomHttpUrlEncoder();

  constructor(
    private readonly injector: Injector,
    public store: Store<IAppState>,
    private readonly tenantService: TenantService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const localStorageService = this.injector.get(LocalStorageService);
    const clonedRequest = request.clone({
      headers: request.headers
        .set(APP_TOKEN_KEY, APP_VERSION)
        .set(APP_NAME_TOKEN_KEY, APP_NAME)
        .set(
          TENANT_HEADER_TOKEN_KEY,
          localStorageService.getItem(TENANT_TOKEN_KEY) || '',
        ),
      params: new HttpParams({
        encoder: this.customHttpUrlEncoder,
        fromString: request.params.toString(),
      }),
      url: this.modifyRequestUrl(request),
    });
    const [requestUrl] = clonedRequest.url.split('?');
    if (
      API_TENANT_URL === requestUrl &&
      HTTP_GET_METHOD === clonedRequest.method
    ) {
      return next
        .handle(clonedRequest)
        .pipe(
          catchError((error: HttpErrorResponse) =>
            this.handleRequestError(request, clonedRequest, next, error),
          ),
        );
    }
    return this.store.select('tenant').pipe(
      filter((tenant: Tenant) => !isEmpty(tenant)),
      take(1),
      map(() => {
        const tenantToken = localStorageService.getItem(TENANT_TOKEN_KEY);
        if (!tenantToken) {
          return clonedRequest;
        }
        return clonedRequest.clone({
          headers: clonedRequest.headers.set(
            TENANT_HEADER_TOKEN_KEY,
            tenantToken,
          ),
        });
      }),
      concatMap((interceptedRequest) =>
        next
          .handle(interceptedRequest)
          .pipe(
            catchError((error: HttpErrorResponse) =>
              this.handleRequestError(request, interceptedRequest, next, error),
            ),
          ),
      ),
    );
  }

  handleRequestError(
    originalRequest: HttpRequest<unknown>,
    clonedRequest: HttpRequest<unknown>,
    next: HttpHandler,
    error: HttpErrorResponse,
  ) {
    const localStorageService = this.injector.get(LocalStorageService);
    if (
      API_TENANT_URL === clonedRequest.url &&
      HTTP_GET_METHOD === clonedRequest.method &&
      HTTP_STATUS_NOT_FOUND === error.status
    ) {
      window.location.href = generateSubdomainUrl('www');
      return EMPTY;
    }
    if (HTTP_STATUS_BAD_REQUEST === error.status) {
      if (
        INVALID_TENANT_TOKEN_ERROR_NAME === error.error?.name &&
        !this.tenantService.isInvalidTenantRequest
      ) {
        return this.store.select('tenant').pipe(
          tap((tenant: Tenant) => {
            const tenantToken = localStorageService.getItem(TENANT_TOKEN_KEY);
            if (isEmpty(tenant) || !tenantToken) {
              this.tenantService.populate();
            }
          }),
          filter(() => !isEmpty(localStorageService.getItem(TENANT_TOKEN_KEY))),
          take(1),
          concatMap(() => {
            const tenantToken = localStorageService.getItem(TENANT_TOKEN_KEY);
            return next.handle(
              clonedRequest.clone({
                headers: clonedRequest.headers.set(
                  TENANT_HEADER_TOKEN_KEY,
                  tenantToken,
                ),
              }),
            );
          }),
        );
      }
      return of(clonedRequest).pipe(
        delay(3000),
        concatMap(() => next.handle(clonedRequest)),
      );
    }
    const authService = this.injector.get(AuthService);
    const shouldThrow =
      NO_TOKEN === authService.getAuthToken() ||
      HTTP_STATUS_UNAUTHORIZED !== error.status ||
      REFRESH_TOKEN_API_URL === originalRequest.url;
    if (shouldThrow) {
      return throwError(() => error);
    }
    return authService.refreshToken().pipe(
      catchError(() => {
        authService.logout();
        return EMPTY;
      }),
      concatMap(({ token }) =>
        this.retryRequest(clonedRequest, next, token).pipe(
          catchError((err) => {
            if (HTTP_STATUS_UNAUTHORIZED === err.status) {
              authService.logout();
              return EMPTY;
            }
            return throwError(() => err);
          }),
        ),
      ),
    );
  }

  retryRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    newAuthToken: string,
  ) {
    const tokenUpdatedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${newAuthToken}`),
    });
    return next.handle(tokenUpdatedRequest);
  }

  modifyRequestUrl(request: HttpRequest<unknown>) {
    const requestUrl = request.url;
    if (
      !isIE ||
      !isEqual(HTTP_GET_METHOD, request.method) ||
      !startsWith(requestUrl, API_PREFIX)
    ) {
      return requestUrl;
    }
    return `${requestUrl}?t=${Date.now()}`;
  }
}
