export const environment = {
  env: 'stage',
  firebase: {
    cloud_messaging_public_key:
      'BJ5EJB-e1FFkrbJ-n6fm4B_0LVXzXhloeG0wvfeoqPQBaXU813_VeVyfmbf9ua-YRRJrCzDmRPEuIUSj8BEa1_w',
  },
  googleMaps: {
    apiKey: 'AIzaSyDaKs4KJGC6p7fDeI17Yk_HDDndS3dM8Ls',
  },
  postHog: {
    apiKey: 'phc_h2LNjy7aYLTtWpEneVucXLC3w6q9MAJqA6IDprLJD3J',
  },
  production: true,
  recaptcha: { siteKey: '6LcAWgkaAAAAAMN50GwVBtL8Kgnf3zAUfOBPRNg9' },
  segment: {
    apiKey: '520noaxd9f',
  },
  workos: { envId: '01F6JRYSHPRRYW31X2T7V6DV2W' },
  workplace: {
    app_id: 1526238874112437,
  },
};
